import { useEffect } from 'react';
import PageLoading from '~/app/components/PageLoading';
import { useAppRouter } from '~/app/lib/router2';

const LoginPage = () => {
  const { redirectPath, prompt } = useAppRouter().getQuery();

  const params = new URLSearchParams();
  params.append('callbackUrl', redirectPath || '/');
  if (prompt) params.append('prompt', prompt);

  useEffect(() => {
    window.location.assign(`/api/auth?${params.toString()}`);
  }, []);

  return <PageLoading />;
};

export default LoginPage;
